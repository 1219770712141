<template>
  <div>
    <ErrorPage v-if="showError" />
    <div class="flex flex-col gap-4 w-full" v-else>
      <c-card class="w-full flex flex-col p-5 pt-0">
        <CardFooter
          reloadcard
          @reload="getOrgTimeTracking"
          @actionModal="isOpenFilter = true"
          @sortType="$queryBuilder({ sort: $event }, getOrgTimeTracking)"
          @searchResult="$queryBuilder({ search: $event }, getOrgTimeTracking)"
        >
          <template v-slot:rightItems>
            <div class="ml-3">
              <c-button
                label="Create New"
                variant="primary btn-bg button-style shadow hover:bg-flame hover:opacity-90"
                @onClick="checkPrivileges('createLocation')"
              />
            </div>
          </template>
        </CardFooter>
      </c-card>

      <c-table
        :headers="headers"
        :items="timeTrackingOptions"
        aria-label="Time Tracking Locations"
        :loading="isFetching"
        :has-number="false"
        :page-sync="true"
        :pagination-list="timeTrackingMeta"
        @page="$queryBuilder({ page: $event }, getOrgTimeTracking)"
        @itemsPerPage="$queryBuilder({ perPage: $event }, getOrgTimeTracking)"
        v-if="timeTrackingOptions.length || isFetching"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.name" class="text-darkPurple text-sm">
            {{ item.data.name }}
          </span>
          <span v-if="item.address" class="text-darkPurple text-sm">
            {{ item.data.address }}
          </span>
          <span v-if="item.employeesCount" class="text-darkPurple text-sm">
            {{ item.data.employeesCount }}
          </span>
          <span v-if="item.clockInRadius" class="text-darkPurple text-sm">
            {{ item.data.clockInRadius }}
          </span>
          <span v-if="item.clockOutRadius" class="text-darkPurple text-sm">
            {{ item.data.clockOutRadius }}
          </span>
          <div
            v-if="item.status"
            class="rounded-md p-2 text-sm w-28 text-center capitalize"
            :class="{
              'bg-green-100 text-mediumSeaGreen': item.data.status === 'active',
              'bg-yellow-100 text-carrotOrange': item.data.status === 'pending',
              'bg-red-100 text-flame': item.data.status === 'inactive',
            }"
          >
            {{ item.data.status }}
          </div>
          <c-menu v-if="item.timeTrackId" left @click.stop="">
            <template v-slot:title>
              <c-icon icon-name="more_icon" size="xs" />
            </template>
            <div class="flex flex-col w-32 h-18 p-2 justify-start items-start">
              <div
                class="w-full"
                @click="checkPrivileges('editLocation', item.data)"
              >
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div
                    class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                  >
                    <c-icon
                      icon-name="edit"
                      class-name="text-blueCrayola"
                      size="xs"
                    />
                    <span class="text-darkPurple text-sm leading-5 font-normal"
                      >Edit</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="w-full"
                @click="checkPrivileges('deleteLocation', item.data)"
              >
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div
                    class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                  >
                    <c-icon
                      icon-name="delete"
                      class-name="text-flame"
                      size="xs"
                    />
                    <span class="text-darkPurple text-sm leading-5 font-normal"
                      >Delete</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="w-full"
                @click="checkPrivileges('deactivate', item.data)"
              >
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div
                    class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                  >
                    <c-icon
                      icon-name="icon-terminate"
                      class-name="text-flame"
                      size="xs"
                    />
                    <span class="text-darkPurple text-sm leading-5 font-normal"
                      >Deactivate</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="w-full"
                @click="checkPrivileges('activate', item.data)"
              >
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div
                    class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                  >
                    <c-icon
                      icon-name="icon-active"
                      class-name="text-mediumSeaGreen"
                      size="xs"
                    />
                    <span class="text-darkPurple text-sm leading-5 font-normal"
                      >Activate</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </c-menu>
        </template>
      </c-table>

      <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
        <c-icon
          icon-name="paygrade_Illustration"
          size=""
          class-name="w-56 h-56"
        />
        <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
          Time tracking locations can be found here. This is empty now, but you
          can start adding time tracking locations to populate this area.
        </p>
      </div>

      <validation-observer rules="required" v-slot="{ handleSubmit }">
        <RightSideBar
          v-if="isOpen"
          @close="onClose"
          submit="Save"
          button-class="bg-dynamicBackBtn text-white"
          @submit="handleSubmit(onSubmit)"
          :disabled-button="isSubmitting"
          :loading="isSubmitting"
          width="width:520px"
        >
          <template v-slot:title>
            <span class="text-darkPurple font-bold text-lg">Create New</span>
          </template>
          <div class="w-full flex flex-col justify-start items-center gap-4">
            <validation-provider
              class="w-full"
              name="Job Level (s)"
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <label class="date-label">Select Location</label>
              <v-select
                :clearable="false"
                class="style-select"
                multiple
                :reduce="(option) => option.id"
                :options="locationOptions"
                :close-on-select="false"
                v-model="payload.locationIds"
                placeholder="Select Location"
                @input="onSelectedLocations($event)"
              >
                <template #option="{ name, id }">
                  <div class="flex">
                    <div class="hover:text-white">
                      <div class="flex justify-start items-center gap-2">
                        <c-checkbox
                          :true-value="!payload.locationIds.includes(id)"
                          @focus.prevent
                          :key="id"
                        />
                        <span class="font-bold text-base cursor-pointer">{{
                          name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{ name }">
                  {{ name }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon icon-name="caret-down" size="xsm" />
                  </span>
                </template>
              </v-select>
              <small class="text-desire">{{ errors[0] }}</small>
            </validation-provider>
            <c-text
              class="w-full"
              :rules="['required']"
              label="Address"
              placeholder="Enter Address"
              v-model="payload.address"
            />
            <div class="w-full">
              <c-text
                class="w-full"
                :rules="['required']"
                label="Clock In Radius (Meters)"
                placeholder="Enter Clock-in radius"
                v-model="payload.clockInRadius"
              />
            </div>
            <div class="w-full">
              <c-text
                class="w-full"
                :rules="['required']"
                label="Clock Out Radius (Meters)"
                placeholder="Enter Clock-out radius"
                v-model="payload.clockOutRadius"
              />
            </div>
          </div>
        </RightSideBar>
      </validation-observer>

      <c-modal v-if="isOpenActivate">
        <c-card class="flex flex-col gap-5 max-w-max py-3 px-5">
          <p
            class="text-jet text-base font-bold border-b border-solid border-romanSilver"
          >
            {{ isActivate === 1 ? "Activate Location" : "Deactivate Location" }}
          </p>
          <div class="flex flex-col justify-start items-start gap-0">
            <p class="text-jet text-base font-semibold">
              Do you want to
              {{ isActivate === 1 ? "activate" : "deactivate" }} this location?
            </p>
          </div>
          <div class="w-full flex justify-center gap-4">
            <c-button
              label="Cancel"
              variant="secondary c-button btn-border"
              :disabled="isActivating"
              @onClick="onClose()"
            />
            <c-button
              variant="primary btn-bg c-button"
              :disabled="isActivating"
              :label="isActivating ? 'Activating...' : 'Activate'"
              @onClick="onSubmitActivate(selectedLocation, 'active')"
              v-if="isActivate === 1"
            />
            <c-button
              v-else-if="isActivate === 2"
              variant="primary btn-bg c-button"
              :disabled="isActivating"
              :label="isActivating ? 'Deactivating...' : 'Deactivate'"
              @onClick="onSubmitActivate(selectedLocation, 'inactive')"
            />
          </div>
        </c-card>
      </c-modal>

      <c-modal v-if="isOpenDeleteLocation">
        <c-card class="flex flex-col gap-5 max-w-max py-3 px-5">
          <p
            class="text-jet text-base font-bold border-b border-solid border-romanSilver"
          >
            Action Required!
          </p>
          <p class="text-jet text-base font-semibold">
            Do you want to delete this location?
          </p>
          <div class="w-full flex justify-center gap-4">
            <c-button
              variant="primary btn-bg c-button"
              :label="isSubmitting ? 'Deleting...' : 'Delete'"
              :disabled="isSubmitting"
              :loading="isSubmitting"
              @onClick="handleDeleteLocation('')"
            />
            <c-button
              variant="secondary c-button btn-border"
              label="Cancel"
              :disabled="isSubmitting"
              @onClick="isOpenDeleteLocation = false"
            />
          </div>
        </c-card>
      </c-modal>

      <FilterTable
        v-if="isOpenFilter"
        :filter-data="filterOptions"
        @close="isOpenFilter = false"
        @applyFilter="onSubmitFilter($event)"
      />
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import CButton from "@scelloo/cloudenly-ui/src/components/button";
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CCheckbox from "@scelloo/cloudenly-ui/src/components/checkbox";
import CIcon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import CModal from "@/components/Modal";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ErrorPage,
    VSelect,
    CCheckbox,
    CText,
    CMenu,
    CButton,
    CCard,
    CIcon,
    CModal,
    RightSideBar,
    CardFooter,
    CTable,
    FilterTable,
  },
  computed: {
    radiusInMeters() {
      return Array.from({ length: 50 }, (_, i) => i + 1);
    },
    filterOptions() {
      return [
        {
          header: "Location(s)",
          value: "location",
          optionsBox: this.locationOptions,
        },
        { header: "Status", value: "status", optionsBox: this.statusOptions },
      ];
    },
  },
  data() {
    return {
      isActivate: 0,
      isOpenActivate: false,
      showError: false,
      isActivating: false,
      isOpenDeleteLocation: false,
      isOpen: false,
      isOpenFilter: false,
      isFetching: false,
      isSubmitting: false,
      isOpenDelete: false,
      timeTrackingOptions: [],
      timeTrackingMeta: {},
      locationOptions: [],
      selectedLocation: {},
      allOption: [],
      headers: [
        { title: "Location Name", value: "name" },
        { title: "Address", value: "address" },
        { title: "Number of Employees", value: "employeesCount" },
        { title: "Clock In Radius (Meters)", value: "clockInRadius" },
        { title: "Clock Out Radius (Meters)", value: "clockOutRadius" },
        { title: "Status", value: "status" },
        { title: "", value: "timeTrackId", image: true },
      ],
      statusOptions: [
        { name: "Active", id: "active" },
        { name: "Inactive", id: "inactive" },
      ],

      payload: {
        locationIds: [],
        address: null,
        clockInRadius: null,
        clockOutRadius: null,
      },
    };
  },
  methods: {
    async checkPrivileges(slug, data) {
      switch (slug) {
        case "createLocation":
          try {
            await this.$handlePrivilege(
              "timeAttendanceLocation",
              "createNewLocation"
            );
            this.isOpen = true;
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              { duration: 5000 }
            );
          }
          break;

        case "editLocation":
          try {
            await this.$handlePrivilege(
              "timeAttendanceLocation",
              "editLocation"
            );
            this.payload = {
              ...data,
              locationIds: data.locationId,
            };
            this.isOpen = true;
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              { duration: 5000 }
            );
          }
          break;

        case "deleteLocation":
          try {
            await this.$handlePrivilege(
              "timeAttendanceLocation",
              "deleteLocation"
            );
            this.handleDeleteLocation(data);
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              { duration: 5000 }
            );
          }
          break;

        case "deactivate":
          try {
            await this.$handlePrivilege(
              "timeAttendanceLocation",
              "deactivateLocation"
            );
            this.onOpenActivate(data, 2);
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              { duration: 5000 }
            );
          }
          break;

        case "activate":
          try {
            await this.$handlePrivilege(
              "timeAttendanceLocation",
              "activateLocation"
            );
            this.onOpenActivate(data, 1);
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              { duration: 5000 }
            );
          }
          break;

        default:
          break;
      }
    },

    async onSubmit() {
      this.isSubmitting = true;

      if (this.payload.timeTrackId) {
        await this.$_updateTimeTracking(this.payload)
          .then(({ data }) => {
            this.$toasted.success(data.message, { duration: 5000 });
            this.onClose();
          })
          .catch((error) => {
            this.$toasted.error(error, { duration: 5000 });
            this.isSubmitting = false;
          });
      } else {
        await this.$_createTimeTracking(this.payload)
          .then(({ data }) => {
            this.$toasted.success(data.message, { duration: 5000 });
            this.onClose();
          })
          .catch((error) => {
            this.$toasted.error(error, { duration: 5000 });
            this.isSubmitting = false;
          });
      }
    },
    async onSubmitActivate(location, status) {
      this.isActivating = true;
      const payload = { ...location, status };

      await this.$_activateTimeTracking(payload)
        .then(({ data }) => {
          this.$toasted.success(data.message, { duration: 5000 });
          this.onClose();
        })
        .catch((error) => {
          this.$toasted.error(error, { duration: 5000 });
          this.isActivating = false;
        });
    },
    onSelectedLocations(items) {
      if (items.includes("all")) {
        this.payload.locationIds = this.locationOptions.map((i) => i.id);
        this.locationOptions = [{ name: "All Locations Selected", id: "all" }];
      } else if (items.length === this.locationOptions.length - 1) {
        this.locationOptions = [...this.allOption];
        this.payload.locationIds = items.filter((item) => item);
      } else {
        this.locationOptions = [...this.allOption];
      }
    },
    async handleDeleteLocation(location) {
      if (!this.isOpenDeleteLocation) {
        this.selectedLocation = location;
        this.isOpenDeleteLocation = true;
        return;
      }

      this.isSubmitting = true;
      const locationId = this.selectedLocation.timeTrackId;
      await this.$_deleteLocations(locationId)
        .then(({ data }) => {
          this.$toasted.success(data.message, { duration: 5000 });
          this.getOrgTimeTracking();
          this.onClose();
        })
        .catch((error) => {
          this.$toasted.error(error, { duration: 5000 });
          this.onClose();
        });
    },
    onOpenActivate(selectedLocation, isActivate) {
      this.selectedLocation = selectedLocation;
      this.isActivate = isActivate;
      this.isOpenActivate = true;
    },
    onClose() {
      Object.assign(this.$data.payload, this.$options.data().payload);
      this.getOrgTimeTracking();
      this.isSubmitting = false;
      this.isActivating = false;
      this.isOpenActivate = false;
      this.isOpenDeleteLocation = false;
      this.isOpen = false;
    },
    onSubmitFilter(options) {
      this.$queryBuilder(options, this.getOrgTimeTracking);
      this.isOpenFilter = false;
    },
    async getOrgTimeTracking(params) {
      this.isFetching = true;
      await this.$_getOrgTimeTracking(params).then(({ data }) => {
        this.timeTrackingOptions = data.locationData || [];
        this.timeTrackingMeta = data.meta || {};
        this.isFetching = false;
      });
    },
    async getOrgLocations() {
      await this.$_getHumanarLocations().then(({ data }) => {
        this.allOption = [{ name: "Select All", id: "all" }, ...data.outlets];
        this.locationOptions = [...this.allOption];
      });
    },

    async bootstrapModule() {
      this.getOrgLocations();
      await this.getOrgTimeTracking();
    },
  },

  async created() {
    try {
      await this.$handlePrivilege("timeAttendanceLocation", "viewRegister");
      this.showError = false;
      this.bootstrapModule();
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}
.c-button {
  width: 100% !important;
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-bg {
  background-color: var(--dynamic-Background) !important;
}

.btn-border {
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
